import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useInject } from '../../hooks/useInject';
import { IxEventList, IxEventListItem, IxPill } from '@siemens/ix-react';
import ReactMarkdown from 'react-markdown';
import PlantKpiService from '../../Services/PlantKpiService';
import styles from './OpenAI.module.scss';
import Spinner from '../../Shared/Spinner/Spinner';

const OpenAIAnalyzer = (chartData: any) => {
    const plantKpiService = useInject(PlantKpiService);
    const [data, setData] = useState(chartData);
    const [screenshot, setScreenshot] = useState<File>();
    const [response, setResponse] = useState<any[]>([]);
    const [isLoading, setLoader] = useState(false);
    const [showContentById, setShowContentById] = useState('');
    const [countdown, setCountdown] = useState(20);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        onDrop: (acceptedFiles: any) => {
            const file = acceptedFiles[0];
            if (file) {
                setScreenshot(file);
            }
        }
    });

    useEffect(() => {
        if (data) {
            handleSubmit();
            setCountdown(20);
        }
    }, [data]);

    useEffect(() => {
        if (countdown > 0) {
            const interval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [countdown]);

    const handleSubmit = async (e?: any) => {
        e?.stopPropagation();
        setCountdown(20);
        setLoader(true);
        setResponse([]);
        setShowContentById('content_0');

        let newData = new FormData();
        if (screenshot) {
            newData.append('image', screenshot);
        }

        try {
            let res = await plantKpiService.analyzeChartData(screenshot ? newData : JSON.stringify(data));
            // Sanitize the JSON string
            res = res.substring(res.indexOf("```json") + 7, res.lastIndexOf("```"));
            res = JSON.parse(res);
            setResponse(res);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setScreenshot(undefined);
            setLoader(false);
            setCountdown(20);
        }
    };



    return (
        isLoading ? (<><span>Fetching summary for you in {countdown} {countdown === 1 ? 'second' : 'seconds'} ...</span> <Spinner /></>) : (
            <div className="container-fluid mt-3" key="rootOfOpenAiAnalyzer">
                <h6>Analytical summary by AI:</h6><br />
                {
                    response.length && (
                        <>
                            <div className='row col-12' style={{ display: 'flex' }}>
                                <div className='col-3' key={'headings'}>
                                    <IxEventList>
                                        {
                                            response.map((res: any, index: number) => {
                                                return (
                                                    <IxEventListItem key={`item_${index}`} color={index % 2 === 0 ? 'color-primary' : 'color-critical--hover'} selected={showContentById === `content_${index}`} onClick={() => setShowContentById(`content_${index}`)}>
                                                        <span key={`heading_${index}`}>{`${index + 1}. ${res.heading}`}</span>
                                                    </IxEventListItem>
                                                )
                                            })
                                        }
                                        <IxEventListItem key={'selectScreenshot'} color='color-warning-40' onClick={() => setShowContentById('selectScreenshot')}>
                                            <span>Want the analysis by image? Click here...</span>
                                        </IxEventListItem>
                                    </IxEventList>
                                </div>
                                <div className={`${styles.contentColumnBorder} col-9`} key={'contents'}>
                                    {
                                        response.map((res: any, index: number) => {
                                            return (
                                                <div key={`contentContainer_${index}`} style={{ display: showContentById === `content_${index}` ? 'block' : 'none', overflowY: 'scroll', maxHeight: '300px' }}>
                                                    <ReactMarkdown key={`contentMarkdown_${index}`}>{res.content}</ReactMarkdown>
                                                </div>
                                            )
                                        })
                                    }
                                    <div {...getRootProps()} key={`screenshotAnalysis`} style={{ display: showContentById === `selectScreenshot` ? 'block' : 'none', overflowY: 'scroll', maxHeight: '300px' }}>
                                        {
                                            !screenshot &&
                                            <>
                                                <input type='file' {...getInputProps()} />
                                                <p>
                                                    <IxPill outline key="drag-drop">Drag 'n' drop or click to select a screenshot here</IxPill>
                                                </p>
                                            </>
                                        }
                                        {
                                            screenshot && (
                                                <div>
                                                    <img src={URL.createObjectURL(screenshot)} className='col-9' height={200} alt='screenshot' /><br />
                                                    <span key={screenshot.name} onClick={() => { setScreenshot(undefined); }}><IxPill outline>{`${screenshot.name} X`}</IxPill></span><br /><br />
                                                    <button className='btn btn-primary' onClick={handleSubmit}>Get summary</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    );
};

export default OpenAIAnalyzer;